<template>
  <v-fab-transition>
    <div class="singleDoctor hideResume " :class="[`doctor${doctorInfo.id}`,info[dataKey] == doctorInfo.id ? 'activeDoctor' : '']"  >
      <div class=" text-center px-5 py-5  " @click="selectItem(doctorInfo.id)">
        <v-fade-transition>
          <div class="select-card-check" v-if="info[dataKey] == doctorInfo.id">
            <v-icon size="18" style="margin-right: 1px;margin-top: -15px;" color="white">mdi-check</v-icon>
          </div>
        </v-fade-transition>
        <div class="avatar">
          <img :src="require('@/assets/doctor/'+doctorInfo.id+'.jpg')" width="100%" alt="">
        </div>
        <p class="nameDoctor">{{ doctorInfo.user.name }} {{ doctorInfo.user.family }} </p>
        <div class="resume">
          <div v-for="(item,i) in doctorInfo.user.resume" :key="i">{{item}}</div>
        </div>
      </div>
      <div class="whiteBlur text-center" @click="DoctorShowingResume ? hideResume(doctorInfo.id) : showResume(doctorInfo.id)">
        <v-icon>
          {{DoctorShowingResume ? 'mdi-menu-up' : 'mdi-menu-down'}}
        </v-icon>
      </div>
    </div>
  </v-fab-transition>
</template>

<script>
import {gsap} from "gsap";

export default {
  props: {
    doctorInfo: Object,
    dataKey: String,
    info: Object,
  },
  data(){
    return{
      DoctorShowingResume:false,
    }
  },
  methods: {
    selectItem(id) {
      this.$emit('itemSelected', this.dataKey, id, false, true)
    },
    showResume(id){
      this.DoctorShowingResume = true
      gsap.to(`.doctor${id}`,{height:'auto',duration:'1'})
    },
    hideResume(id){
      this.DoctorShowingResume = false
      gsap.to(`.doctor${id}`,{height:'210px',duration:'1'})
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px ) and (max-width: 600px) {
  .singleDoctor .avatar {
    height: 80px !important;
  }
}

.avatar img {
  height: 100% !important;
}

.singleDoctor {
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  border: solid 1px #d1dcff;
  overflow: hidden;
  background: white;
  transition: all 0.3s;

}


.singleDoctor:hover {
}

.singleDoctor .avatar {
  border: solid 1px #dbdbdb;
    overflow: hidden;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  margin: auto auto;

}

.singleDoctor .nameDoctor {
  font-size: 13pt;
  color: #000;
  text-align: center;
  /*opacity: 0.8;*/
  margin-top: 5px;
  font-weight: 300 !important;
}

.activeDoctor {
  border: solid 3px #4576d8;
  background: white;
  transition: all 0.3s;
}

.select-card-check {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #4576d8;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  transition: all 0.3s;
}
.resume{
  font-size: 13px;
  margin-top: -15px;
  color: #b9b9b9;
  line-height: 20pt;
}
.whiteBlur{
  position: absolute;
  bottom:  0;
  /*background: white;*/
  right: 0px;
  height: 30px;
  left: 0px;
  background: linear-gradient(to  top, white, transparent);
}
@keyframes show {
  0%{
    height: initial;
  }
  100%{
    height: auto;
  }
}
.showResume{
  /*animation: show 2s ;*/
  /*height: auto;*/
  /*transition: all 1s;*/
}
.hideResume{

  height: 210px;
  /*transition: all 0.3s;*/
}
</style>