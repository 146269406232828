<template>
  <div>
    <div class="stepper-item-com estedad-font step5" >

      <v-card color="transparent" elevation="0" style="">
        <div class="text-center mt-10" style="font-size: .8em;">
          {{ $t('$vuetify.onBoarding.stepFive.title') }}
        </div>
        <v-card-text>

          <!--                    <v-row justify="center" align="center" style="width: 100%" class="mx-auto">-->
          <v-row style="width: 100%" class="mx-auto" :justify="isMobile?'right':'center'"
                 :align="isMobile?'right':'center'">

            <SelectableWizardCard
                v-for="(relationship,n) in dataComponent"
                :key="n"
                @itemSelected="selectItem" :info="info" :item="relationship"
                data-key="relationship"/>
          </v-row>
          <v-row align="center" justify="center">

          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SelectableWizardCard from "@/components/wizard/SelectableWizardCard";

export default {
  components: {SelectableWizardCard},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    datakey: String
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      console.log(arguments, 'sdvsdsdsdsvdsvssdvdsvs')
      this.$emit('selectItem', key, value, false, true)
    }
  },
  mounted() {
  }
}
</script>


<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
</style>