<template>
  <div class="stepper-item-com" >
    <template>
      <v-card color="transparent" elevation="0" style="" class="py-5 ">
        <p>
          {{$t('$vuetify.onBoarding.whoSelectDoctor.title')}}
        </p>

        <SelectWizardWhoSelectDoctor :title="$t('$vuetify.onBoarding.whoSelectDoctor.items[0]')" value="me" :who="whoselectDoctor"
                                     @whoSelectHandler="setWhoSelectDoctor($event)" v-ripple/>
        <SelectWizardWhoSelectDoctor :title="$t('$vuetify.onBoarding.whoSelectDoctor.items[1]')" value="simiaroom"
                                     :who="whoselectDoctor" @whoSelectHandler="setWhoSelectDoctor($event)"
                                     v-ripple/>
        <br>
      </v-card>
    </template>
  </div>
</template>

<script>

import SelectWizardWhoSelectDoctor from "@/components/wizard/SelectWizardWhoSelectDoctor";
export default {
  components: {SelectWizardWhoSelectDoctor},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    datakey: String,
    whoselectDoctor:String,
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      this.$emit('selectItem', key, value, false, true)
    },
    setWhoSelectDoctor(val){
      this.$emit('whoSelectHandler',val)
    }
  },
  mounted() {
  }
}
</script>


<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
</style>