<template>
    <div class="stepper-item-com " style="flex-direction:row;height:65vh">
      <div style="position: absolute;right:0;bottom: 30px;" v-if="!ismobile">
        <img style="width:85%" :src="require('@/assets/new-design/furniture.svg')" alt="">
      </div>

      <div style="flex-grow: 2" v-if="!ismobile"></div>
      <v-card color="transparent " :class="{'':!ismobile}" elevation="0" class="login-register-forms"
              style="min-width:350px;">
        <v-window v-model="loginwindow" :reverse="this.$store.getters.isRTL">
          <v-window-item :value="1" class="">
            <LoginComponent @changeWindow="updateWindow"
                            :GALogin="'ga_login_new_preorder'"
                            preorderLogin="true"
                            @userLoggedIn="afterLogin"
                            :subtitle="$t('$vuetify.onBoarding.titleLogin')"></LoginComponent>
          </v-window-item>
          <v-window-item :value="2">

            <SignupComponent @changeWindow="updateWindow"
                             :GASignup="'ga_signup_new_preorder'"
                             preorderLogin="true"
                             @userLoggedIn="afterLogin"
                             wizard="true"
                             :title="$t('$vuetify.onBoarding.titleLogin')"

            ></SignupComponent>

          </v-window-item>
        </v-window>
        <div style="min-height: 70px;">
          <div class="mobile-methods-of-login " style="overflow: hidden!important;min-height: 35px;"
               v-if="ismobile">
            <div class="text-center mt-1 " style="font-size:.55em;color:#6d5aac;cursor: pointer;"
                 @click="animateLoginMethods">
              {{ $t('$vuetify.onBoarding.loginWithDifferentWay') }}
            </div>
            <div class="justify-space-around login-methods my-1" ref="loginMethods">
              <div class="mx-1  px-1 flex-grow-1">
                <v-btn text fab small @click="loginBy('google')">
                  <img width="100%" :src="require('@/assets/new-design/sociallogin/google.svg')" alt="">
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div v-if="ismobile" class="login-methods-mobile"></div>
      </v-card>
      <div class="" style="flex-grow: 1" v-if="!ismobile"></div>
      <div class="d-flex "
           v-if="!ismobile"
           style="height: 85%;justify-content: center;align-items: center;flex-direction: column;position: relative">
        <div class="text-center login-decision-circle"
             style="">
          {{ $t('$vuetify.or') }}
        </div>
        <div style="width:1px;background-color: #b9a0f6;height:90%;margin-top:5%">
        </div>
      </div>
      <div class="" style="width:35%;padding:10px;" v-if="!ismobile">
        <div class="text-center">
          <div style="font-size: .7em;color: #6d5aac">
            {{ $t('$vuetify.onBoarding.loginWithDifferentWay') }}
          </div>
          <!--                      <v-card class="my-5 login-methods-item" color="transparent">-->
          <!--                        <img :src="require('../assets/new-design/sociallogin/apple.svg')" alt="">-->
          <!--                        <div style="color:#6d6d6d;font-size: .5em;position: relative;bottom: 10px;">حساب اپل-->
          <!--                        </div>-->
          <!--                      </v-card>-->
          <v-card class="my-5 login-methods-item" color="transparent" @click="loginBy('google')">
            <img :src="require('@/assets/new-design/sociallogin/google.svg')" alt="">
            <div style="color:#6d6d6d;font-size: .5em;position: relative;bottom: 10px;">
              {{ $t('$vuetify.onBoarding.loginItems[0]') }}
            </div>

          </v-card>

        </div>

      </div>
    </div>
</template>

<script>
import LoginComponent from '@/components/LoginComponentWizard.vue'
import SignupComponent from '@/components/SignUpComponentWizard.vue'
import gsap from "gsap";

export default {
  props: {
    ismobile: Boolean,
    loginwindow:Number
  },
  data() {
    return {
      showMethods: true,
    }
  },
  methods: {
    updateWindow(variable) {
      this.loginwindow = variable
      this.loginRegisterBtn = variable == 1 ? 'login' : 'register';
    },
    afterLogin() {
      this.$emit('afterLogin')
    },
    animateLoginMethods() {
      if (this.showMethods) {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '40px',
          opacity: 1,
          display: 'flex',
          duration: 0
        }, {
          height: '0px',
          opacity: 0,
          duration: .5,
          display: 'none'
        });
        this.showMethods = false;
      } else {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '0px',
          opacity: 0,
          display: 'none',
          duration: 0
        }, {
          height: '40px',
          opacity: 1,
          duration: .5,
          display: 'flex'
        });
        this.showMethods = true;
      }
    },
    loginBy(target) {
      switch (target) {
        case 'google':
          window.location.href = 'https://api.simiaroom.com/api/v1/auth/social/google';
      }
    },
  },
  components: {LoginComponent, SignupComponent}
}
</script>

<style scoped>
.login-methods-mobile {
  width: 100%;
  height: 50px;
}
.stepper-item-com {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
  width: 100vh;
}
.login-decision-circle {
  width: 30px;
  height: 30px;
  border: 1px solid #6d5aac;
  border-radius: 50px;
  position: absolute;
  top: 10%;
  font-size: .5em;
  line-height: 30px;
  color: #574596;
  z-index: 2;
}
.line{
  width: 200px;
  height: 10px;
  background: red;
  transform: rotate(90deg);
  z-index: 1;
}
@media screen and (max-width: 500px) {
  .login-register-forms {
    width: 100%;
  }
  .stepper-item-com{
    width: 100% !important;
  }
}

.mobile-methods-of-login {
  border: solid 1px #6d5aac;
  border-radius: 10px !important;
  width: 90%;
  margin: 0 auto;
}

.login-methods {
  display: flex;
  line-height: 30px;
  text-align: center;
}



.login-methods-item:hover {
  background-color: white !important;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.07);
}

.login-methods-item {
  width: 45%;
  margin: 0 auto;
  border-radius: 10px !important;
  /*border: solid 2px #dbdbdb !important;*/
  transition: all .3s linear !important;
  cursor: pointer;
  box-shadow: none;
}



</style>