<template>
  <div class="" style="" >
    <div class="checkboxTime" v-ripple @click="selectTime">
      <div class="select" v-if="this.selectedDay.findIndex(item => item === this.daysIndex) != -1"></div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    selectedDay:Array,
    objectKey:String,
    daysIndex:Number,
  },
  data(){
    return{
      isSeleted:false,
    }
  },
  watch: {

  },
  methods:{
    selectTime(){
      this.$emit('selectTime',{key : this.objectKey , value:this.daysIndex})
    },

  }

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .checkboxTime{
    margin-top: 0px !important;
    margin-right: 25px !important;
  }
}
.checkboxTime{
  width: 20px;
  height: 20px;
  background-color: rgba(209, 220, 255, 0.1);
  margin-top: 21px;
  border: 1px solid #78a5ff;
  border-radius: 5px;
  padding: 1px;
}
.select{
  background: #1c53c1;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: auto;
}

</style>