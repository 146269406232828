<template>
  <div class="stepper-item-com">
    <template>
      <v-card color="transparent" elevation="0" style="">
        <div class="text-center mt-10" style="font-size: .8em;">
          {{ $t('$vuetify.onBoarding.stepTwo.title') }}
          <div>
                                                                <span style="" class="step-subtitle text-right">
                                                                  <div class="step-subtitle-bullet"
                                                                       :style="checkLang == 'fa' ? 'right: -8px' : 'left: -8px'"></div>
                                                                  {{ $t('$vuetify.onBoarding.stepTwo.subtitle') }}
                                                                </span>
          </div>
          <!--          <v-btn @click="nextHandler">sdvsv</v-btn>-->
        </div>
        <v-card-text>
          <v-row :justify=" isMobile ?'right':'center'" :align=" isMobile?'right':'center'" v-if="dataComponent">
            <SelectableWizardCard
                v-for="(cycle,n) in dataComponent"
                :key="n"
                :ref="'select-card'"
                animation="stagger-top"
                @itemSelected="selectItem" :info="info" :item="cycle"
                :data-key="datakey"/>
          </v-row>
        </v-card-text>

      </v-card>
    </template>
  </div>
</template>

<script>
import SelectableWizardCard from "@/components/wizard/SelectableWizardCard";

export default {
  components: {SelectableWizardCard},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    // key:String,
    datakey: String
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      console.log(arguments, 'sdvsdsdsdsvdsvssdvdsvs')
      this.$emit('selectItem', key, value, false, true)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
</style>