<template>
  <div>
    <div class="stepper-item-com">
      <div class="fill-height mt-10 px-2" style="position:absolute;">
        <div :style="$vuetify.breakpoint.mdAndUp ? 'width: 500px !important' : 'width: 100% !important'">
          <v-text-field :label="$t('$vuetify.onBoarding.selectDoctor.searchPlaceHolder')" class="formSearchDoctor" v-model="searchParams">
            <template v-slot:prepend>
              <v-icon>mdi-magnify</v-icon>
            </template>
          </v-text-field>
          <div class="categorySelected">
            <div class="d-flex">
              <div class="titleCategory mt-3">
                {{$t('$vuetify.onBoarding.selectDoctor.labelCategory')}}
                 :
              </div>
              <v-spacer></v-spacer>
              <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
                <div v-for="(item , i ) in category" :key="i" class=" mx-1">
                  <v-btn class="categoryItem " @click="setCategory(item.key)" color="transparent" elevation="0" x-small
                         :class="categorySelected === item.key ? 'activeCategory' : ''">
                    {{ item.title }}
                  </v-btn>
                </div>
              </div>
              <div v-else>
                <v-select
                    height="20"
                    dense
                    style="width: 150px"
                    color="blue"
                    outlined
                    :items="category"
                    item-text="title"
                    item-value="key"
                    v-model="categorySelected"
                ></v-select>
              </div>

            </div>
          </div>
          <v-row v-if="renderingDoctor">
            <v-col cols="12" lg="6" v-for="(item , i ) in renderDoctor " :key="i">
              <v-fade-transition>
                <SelectWizardDoctor :doctor-info="item" :data-key="'doctor'" :info="info" @itemSelected="selectItem" />
              </v-fade-transition>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectWizardDoctor from "@/components/wizard/SelectWizardDoctor";

export default {
  components: {SelectWizardDoctor},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    datakey: String,

  },
  computed: {
    renderDoctor() {
      return this.dataComponent.filter(item => {
        if (this.searchParams != null) {
          if (item.user.name.includes(this.searchParams)) {
            if (this.categorySelected != null) {
              if (item.user.specialty.includes(this.categorySelected)) {
                return item
              }
            } else {
              return item
            }
          }
        } else {
          if (this.categorySelected != null) {
            if (item.user.specialty.includes(this.categorySelected)) {
              return item
            }
          } else {
            return item
          }
        }
      })
    }
  },
  data() {
    return {
      searchParams: null,
      categorySelected: null,
      renderingDoctor: true,
      category: [
        {
          title: this.$t('$vuetify.onBoarding.selectDoctor.all'),
          key: null,
        },
        {
          title: this.$t('$vuetify.clientStepper.addCycleModal.categories[0]'),
          key: 'فردی',
        },
        {
          title: this.$t('$vuetify.clientStepper.addCycleModal.categories[1]'),
          key: 'رابطه و زوج درمانی',
        },
        {
          title: this.$t('$vuetify.clientStepper.addCycleModal.categories[3]'),
          key: 'کودک و نوجوان',
        },
        {
          title: this.$t('$vuetify.clientStepper.addCycleModal.categories[5]'),
          key: 'روانپزشکی',
        }
      ]
    }
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      this.$emit('selectItem', key, value, false, true)
    },
    setCategory(category) {
      this.categorySelected = category
      this.configRenderingDoctor()

    },
    configRenderingDoctor() {
      this.renderingDoctor = false
      setTimeout(() => {
        this.renderingDoctor = true
      }, 100)
    }
  },
  mounted() {
    this.categorySelected = this.info.category
  },
  watch: {
    searchParams() {
      this.configRenderingDoctor()
    },
    categorySelected() {
      this.configRenderingDoctor()
    },
    'info.category'() {
      this.categorySelected = this.info.category
    }
  },

}
</script>


<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .stepper-item-com {
    width: 200px !important;
  }

}

.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}

.categorySelected .titleCategory {
  font-size: 12px;
  color: #6d6d6d;
}

.categoryItem {
  /*border-radius: 5px;*/
  /*border: solid 1px #dbdbdb;*/
  font-size: 10px;
  padding: 2px 16px;
  color: #000;
  font-weight: 300;
  border-radius: 5px !important;
  border: solid 1px #dbdbdb !important;
}

.activeCategory {
  background: #1c53c1 !important;
  color: white;
}
</style>