<template>
  <div class="stepper-item-com ">
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="text-center fill-height mt-12" style="position:absolute;width: 500px;">
        <p class="titleSelectTime">
          {{$t('$vuetify.onBoarding.selectTime.title')}}
        </p>
        <p class="subTitleSelectTime">
          {{$t('$vuetify.onBoarding.selectTime.subTitle')}}
        </p>
        <v-row class="mt-12">
          <v-col cols="9" lg="9" class="" style="margin-top: -40px">
            <div>
              <div class="d-flex justify-space-between flex-row-reverse">

                <div v-for="(item , i) in Object.keys(dataComponent)" :key="i" :class="i % 2 != 0 ? 'greyRow ' : ''"
                     style="width: 100%;height: 100%;padding-bottom: 20px">
                  <span class="dayLabel">{{ item }}</span>
                  <div v-for="n in 4" :key="n" >
                    <div class="d-flex justify-space-around ">
                      <div>
                        <SelectTimeWizard :object-key="item" :days-index="n"
                                          @selectTime="selectingTime($event)" :selected-day="dataComponent[item]"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3" lg="3">
            <div class="timesArea">
              <p class="times" v-for="(item , i) in times" :key="i">
                {{ item.title }}
                <img :src='require("@/assets/newOnboading/" + item.pic)' width="20" alt="">
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else-if="$vuetify.breakpoint.smAndDown">
      <div style="position: absolute" class="fill-height text-center mt-10">
        <p class="titleSelectTime">
          {{$t('$vuetify.onBoarding.selectTime.title')}}
        </p>
        <p class="subTitleSelectTime">
          {{$t('$vuetify.onBoarding.selectTime.subTitle')}}
        </p>
        <div class="fill-height  ">
          <div class=" d-flex mt-5 flex-row-reverse justify-space-between " :style="!$vuetify.rtl ? 'margin-right:  50px' : 'margin-left:  15px'" >
            <div v-for="(item,i) in times" :key="i" class=" mx-4">
              <img :src='require("@/assets/newOnboading/" + item.pic)' width="20" alt="">
              <p class="dayLabel">
                {{ item.title }}
              </p>
            </div>
          </div>
          <div class="mt-3 " :class="!$vuetify.rtl ? 'ml-5' :''">
            <v-row :style="$vuetify.rtl ? 'margin-left: -50px ;' : 'margin-right: -35px;'" v-for="(item , i ) in Object.keys(dataComponent)" :key="i" :class="i % 2 != 0 ? 'greyRow' : ''">
              <v-col cols="9">
                <div class="d-flex justify-space-between ">
                  <div v-for="n in 4 " :key="n" class=""  style="margin-right: -10px">
                    <SelectTimeWizard class="" :object-key="item" :days-index="n"  @selectTime="selectingTime($event)" :selected-day="dataComponent[item]"/>
                  </div>
                </div>
              </v-col>
              <v-col cols="2">
                <p class="dayLabel text-left">
                  {{ item }}
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>

import SelectTimeWizard from "@/components/wizard/selectTimeWizard";
export default {
  components: {SelectTimeWizard},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Object,
    datakey: String
  },
  data(){
    return{
      times: [
        {
          title: "8-12",
          pic: 'morning.png'
        },
        {
          title: '12-16',
          pic: 'Noon.png'
        },
        {
          title: '16-20',
          pic: 'afterNoon.png'
        },
        {
          title: '20-24',
          pic: 'night.png'
        }

      ],
    }
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      this.$emit('selectItem', key, value, false, true)
    },
    selectingTime(val){
      this.$emit('selectingTime',val)
    }
  },
  mounted() {
  }
}
</script>


<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
.titleSelectTime {
  font-size: 14px;
}

.subTitleSelectTime {
  margin-top: -10px;
  font-size: 12px;
}


.dayLabel {
  color: #1c53c1;
  font-size: 12px;
}

.greyRow {
  background: #FBFCFF;
  height: 50px;
}
.headerMobileTimes {
  background: url("../../assets/newOnboading/backHeaderMobile.png");
  background-size: cover;
  width: 150%;
  margin-right: -20%;
  padding-right: 50%;
  padding-left: 50%;
  direction: rtl !important;
}

</style>